* {
  outline: none;
}

html {
  font-size: 12px;
  font-size-adjust: none;
}

body,
html {
  height: 100vh;
  width: 100%;
  margin: 0px;
  padding: 0px;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1.1rem;
  font-family: "Lato", "Montserrat", "Roboto", "Arial", sans-serif;
  font-weight: 400;
  width: 100%;
  width: -moz-available;
  height: 100%;
  height: -moz-available;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #2e2f32;
}

tr {
  height: 100px;
}

/* Classes for the displayed toast */
.Toastify__toast {
  min-height: 48px !important;
}

.Toastify__toast-body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.Toastify__toast--dark {
  background: #26282a !important;
}

.Toastify__toast--default {
}

.Toastify__toast--info {
  background: #009fea !important;
}

.Toastify__toast--success {
  background: #613dc1 !important;
}

.Toastify__toast--warning {
  background: #f1d45c !important;
}

.Toastify__toast--error {
  background: #ea6450 !important;
}

/* Classes for the Material UI Lab */
.MuiAlert-root {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.MuiAlert-icon {
  /* color: #fff !important; */
}

.MuiAlert-message {
  /* color: #fff !important; */
}

.MuiAlert-standardSuccess {
  background-color: #613dc1 !important;
}

.MuiAlert-standardInfo {
  background-color: #009fea !important;
}

.MuiAlert-standardWarning {
  background-color: #f1d45c !important;
}

.MuiAlert-standardError {
  background-color: #ea6450 !important;
}
